<template>
  <v-container grid-list-md fluid>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-toolbar flat class="transparent">
          <v-breadcrumbs :items="breadcrumbs" divider=">">
            <template slot="item" slot-scope="props">
              <a
                @click="goHref(props.item)"
                :disabled="props.item.disabled"
              >{{ props.item.text.toUpperCase() }}</a>
            </template>
          </v-breadcrumbs>
        </v-toolbar>
        <v-tabs centered slider-color="primary" v-model="tabs">
          <v-tab>Dados Principais</v-tab>
          <v-tab-item>
            <unidadeCad tipo="C" />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const unidadeCad = () => import('./../../../components/Unidade/UnidadeCad')

export default {
  name: 'CanalCad',
  components: {
    unidadeCad
  },
  data () {
    return {
      valid: true,
      tabs: 1,
      breadcrumbs: [
        {
          text: 'Lista de canais',
          disabled: false,
          href: '/canal'
        },
        {
          text: 'Novo canal',
          disabled: true,
          href: '/canal/cad'
        },
      ]
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.breadcrumbs[1].text = 'Edição de canal'
    }
  },
  methods: {
    goHref (item) {
      if (!item.disabled) {
        this.$router.push(item.href)
      }
    }
  }
}
</script>